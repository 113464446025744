import { application } from '~/controllers/application'
import { registerControllers } from 'stimulus-vite-helpers'

const controllers = import.meta.globEager([
  '~/controllers/start_building_button_controller.js',
  '~/controllers/home_get_started_card_controller.js',
  '~/controllers/machine_apps_observer_controller.js',
])

registerControllers(application, controllers)
